import BaseModel from "@/base/model/BaseModel";

export default class Document extends BaseModel {
  constructor() {
    super();
    this.name = null;
    this.type = null;
    this.url = null;
    this.mobileAccessible = false;
  }
}
