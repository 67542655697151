var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-page-layout',{attrs:{"title":_vm.$t('hr.document.add_document')},on:{"close":_vm.leavePage},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-card',{attrs:{"disabled":_vm.loading,"flat":""}},[_c('validation-observer',{ref:"form"},[_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.document.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('hr.document.name'),"error-messages":errors},model:{value:(_vm.document.name),callback:function ($$v) {_vm.$set(_vm.document, "name", $$v)},expression:"document.name"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('hr.document.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('document-type-picker',{attrs:{"error-messages":errors},model:{value:(_vm.document.type),callback:function ($$v) {_vm.$set(_vm.document, "type", $$v)},expression:"document.type"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('validation-provider',{attrs:{"name":_vm.$t('global.file'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('file-upload-picker',{attrs:{"on-create":"","storageType":"DOCUMENT","error-messages":errors},on:{"send":function (cb) { return (_vm.onFileSend = cb); }},model:{value:(_vm.document.url),callback:function ($$v) {_vm.$set(_vm.document, "url", $$v)},expression:"document.url"}})]}}])})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-switch',{attrs:{"color":"primary","label":_vm.document.mobileAccessible
                ? _vm.$t('hr.document.mobile_accessible_enable')
                : _vm.$t('hr.document.mobile_accessible_disable')},model:{value:(_vm.document.mobileAccessible),callback:function ($$v) {_vm.$set(_vm.document, "mobileAccessible", $$v)},expression:"document.mobileAccessible"}})],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('app-page-footer',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.validateAndSave}},[_vm._v(_vm._s(_vm.$t("buttons.save")))])]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }